<template>
  <div class="product-content-container">
    <div class="product-card-container" v-if="products">
      <div
        class="product-card"
        v-for="element in products"
        :key="element._id"
        @click="goToDetail(element.name, element._id)"
      >
        <div class="card-image">
          <img
            :src="element.image | setImage"
            :alt="element.name"
            class="product-image"
            loading="lazy"
          />
        </div>
        <div class="card-title">{{ element.name }}</div>
        <hr class="hr80" />
        <div class="see-button">Ürünü Gör</div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
export default {
  name: "Content",
  props: ["products"],
  data() {
    return {
      items: "",
    };
  },

  methods: {
    goToDetail(name, id) {
      let path = slugify(name, {
        replacement: "-",
        lower: true,
        remove: /[*+~.()'"!:@]/g,
      });
      this.$router.push(
        {
          path: `/urun/${path}`,
          query: {
            id: id,
          },
        },
        () => {}
      );
    },
  },
  filters: {
    setImage: function (value) {
      if (!value) return "";
      return `${process.env.VUE_APP_IMAGEURL_ENDPOINT}/${value}?tr=w-500,h-400,cm-pad_resize,bg-000`;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";

.content-title {
  height: auto;
  display: flex;
  width: 100%;
  align-items: center;
  color: #777777;
  font-size: 24px;
  font-weight: 500;
  padding: 0.5rem 0 0 0.5rem;
}
.product-card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 1rem;
  .product-card {
    width: calc(100% / 3);
    height: auto;
    /* margin-right: 2rem; */
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @include xs {
      width: calc(100%);
    }
    @include sm {
      width: calc(100% / 2);
    }

    .card-image {
      max-width: 250px;

      .product-image {
        max-width: 100%;
      }
    }
    .card-title {
      color: #777777;
      font-weight: 500;
      height: 30px;
      display: flex;
      align-items: center;
      width: calc(100%);
      justify-content: center;
      text-align: center;
      margin: 0;
    }
    .hr80 {
      width: 80%;
      margin: 0.5rem 0;
    }
    .see-button {
      color: orangered;
      font-weight: 700;
      width: 100%;
      text-align: center;
    }
  }
  .show {
    display: flex;
  }
  .none {
    display: none;
  }
}
</style>
