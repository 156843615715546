import axios from "axios";
export default {
  state: {
    navbarItems: [],
    categoryDetail: [],
  },
  mutations: {
    GET_CATEGORIES(state, payload) {
      let items = [];
      payload.map((item) => {
        if (item.productCount > 0) {
          items.push(item);
        }
      });
      state.navbarItems = items;
    },
    GET_CATEGORY_DETAIL(state, payload) {
      state.categoryDetail = payload;
    },
  },
  actions: {
    getCategories({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/category/get-categories`)
        .then((res) => {
          commit("GET_CATEGORIES", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCategoryDetail({ commit }, payload) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/category/get-category`, {
          headers: { id: payload },
        })
        .then((res) => {
          commit("GET_CATEGORY_DETAIL", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  getters: {},
};
