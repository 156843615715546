<template>
  <div class="product-page-container">
    <div class="content-container">
      <div class="rotater">
        <Rotation :rotate="pageDetail.rotation" />
      </div>
      <div class="content">
        <SideBar :menu="pageDetail.sidebar" />
        <Content :products="pageDetail.products" />
      </div>
    </div>
  </div>
</template>

<script>
import Content from "../components/ProductPage/Content.vue";
import SideBar from "../components/ProductPage/SideBar.vue";
import Rotation from "../components/global/Rotation.vue";

export default {
  name: "Product",
  components: { SideBar, Content, Rotation },
  data() {
    return {
      pageDetail: [],
    };
  },
  watch: {
    $route(to, from) {
      let toID = to.query.id;
      let fromID = from.query.id;
      if (toID != fromID) {
        this.getCategoryPageDetail();
      }
    },
  },
  methods: {
    getCategoryPageDetail() {
      let path = this.$route.query.id;
      this.$store.dispatch("getCategoryPageDetails", path).then(() => {
        this.pageDetail = this.$store.state.products.categoryPageDetails;
      });
    },
  },

  created() {
    this.getCategoryPageDetail();
  },
};
</script>

<style lang="scss">
@import "../scss/responsive.scss";
.product-page-container {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  min-height: 100vh;
  .content-container {
    width: 1400px;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      justify-content: space-between;
      @include xs {
        padding: 0 1rem;
      }
      @include sm {
        padding: 0 1rem;
      }
      @include md {
        padding: 0 1rem;
      }
      .product-sidebar-container {
        width: 300px;
        display: flex;
        flex-direction: column;
        @include xs {
          display: none;
        }
        @include sm {
          display: none;
        }
        @include md {
          display: none;
        }
      }
      .product-content-container,
      .product-detail-container {
        width: calc(100% - (300px + 2rem));
        border: 1px solid #e0e0e0;
        display: flex;
        flex-direction: column;
        @include xs {
          width: 100%;
          border: none;
        }
        @include sm {
          width: 100%;
          border: none;
        }
        @include md {
          width: 100%;
          border: none;
        }
      }
    }
  }
}
</style>
