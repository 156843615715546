import axios from "axios";

export default {
  state: {
    loginResult:{},
    checkStatus: false,
  },
  mutations: {
    loginResult(state, payload) {
      state.loginResult = payload
    },

    CHECK_STATUS(state, payload) {
      state.checkStatus = payload.status;
    },
  },
  actions: {
    async loginUser(context, payload) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/user/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        sessionStorage.setItem("user", data.status);
        sessionStorage.setItem("uuid", data.id);
        context.commit("loginResult", {
          status: data.errors ? false : true,
          message: data.errors ? data.errors : "",
          id: data.errors ? "" : data.id,
        });
      } catch (err) {
        console.log(err);
      }
    },
    checkUser({ commit }, payload) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}/api/user/check`, payload)
        .then((res) => {
          commit("CHECK_STATUS", res.data);
        })
        .catch(() => {
          commit("CHECK_STATUS", { status: false });
        });
    },
  },
};

/* 
 return axios
        .post(`${process.env.VUE_APP_API_URL}/api/user/login`, payload)
        .then((res) => {
          console.log(res.data)
          commit("LOGIN_SUCCESS", res.data);
          sessionStorage.setItem("user", res.data.status);
          sessionStorage.setItem("uuid", res.data.userID);
        })
        .catch((e) => {
          commit("LOGIN_FAILED", e.response.data);
        });
*/
