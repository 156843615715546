import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import categories from "./modules/categories";
import products from "./modules/products";
import message from "./modules/message";
import admin from "./modules/admin";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    categories,
    products,
    message,
    admin,
  },
});
