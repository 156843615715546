<template>
  <div class="image-box-container">
    <div class="image-box-preview">
      <img :src="imageURL | setImage" alt="" />
      <span class="close-icon"
        ><i class="fas fa-times" @click="closePopUp"></i
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBox",
  props: ["imageURL"],
  methods: {
    closePopUp() {
      this.$emit("closeImage");
    },
  },

  filters: {
    setImage: function (value) {
      if (!value) return "";
      return `${process.env.VUE_APP_IMAGEURL_ENDPOINT}/${value}?tr=w-500,h-400,cm-pad_resize,bg-000`;
    },
  },
};
</script>

<style lang="scss">
.image-box-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  .image-box-preview {
    position: relative;
    width: auto;
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      border: 2px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;
      box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.2);
    }
    .close-icon {
      position: absolute;
      top: 18px;
      right: 20px;
      font-size: 1.5rem;
      color: #777777;
      cursor: pointer;
      &:hover {
        color: orangered;
      }
    }
  }
}
</style>
