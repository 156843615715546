<template>
  <div class="header-container">
    <!-- Top Side -->
    <div class="top-info-bar-container">
      <div class="top-info-bar">
        <div class="left-side">
          <div class="item">
            <a
              href="https://www.instagram.com/cozumpaslanmaz06/"
              target="_blank"
            >
              <i class="fab fa-instagram"></i> Instagram
            </a>
          </div>
          <div class="item">
            <a href="mailto:cozumpaslanmaz@gmail.com">
              <i class="fas fa-envelope"></i>E-Mail
            </a>
          </div>
        </div>

        <div class="right-side">
          <!-- <router-link to="/products">Ürünler</router-link> -->
          <router-link to="/iletisim">İletişim</router-link>
        </div>
      </div>
    </div>

    <!-- Logo Section -->
    <div class="logo-section">
      <div class="logo-container">
        <img
          :src="require('@/assets/logo.svg')"
          alt="Logo"
          class="mainlogo"
          @click="goHomePage"
        />
        <div class="slogan">
          <span>Çözüm Paslanmaz</span>
          <span>Paslanmaz Cam Aksesuarları</span>
        </div>
      </div>

      <div class="lang-menu">
        <span class="lang-selection selected">TR</span>
        <span class="vertical-line"></span>
        <span class="lang-selection">EN</span>
      </div>
    </div>
    <!-- Navbar Section -->
    <div :class="screenWidth > 1009 ? 'desktop-navbar' : 'mobile-navbar'">
      <span class="navbar-button" @click="navbarDisplay = !navbarDisplay"
        ><i class="fas fa-bars"></i
      ></span>
      <div class="item-group" :class="navbarDisplay ? 'active' : ''">
        <span
          class="navbar-item"
          v-for="(item, index) in navbar"
          :key="index"
          @click="goTo(item.url, item._id)"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "@/assets/navbar.json";
export default {
  name: "Header",
  data() {
    return {
      navbarDisplay: false,
      screenWidth: "",
      navbar,
    };
  },
  created() {
    this.screenWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },

  methods: {
    goHomePage() {
      this.$router.push({ path: "/" }, () => {});
    },
    goTo(url, id) {
      this.$router.push(
        {
          path: `/kategori/${url}`,

          query: { id: id },
        },
        () => {}
      );
    },
  },
};
</script>
<style lang="scss">
@import "../../scss/responsive.scss";

/*Colors*/
$primary: orangered;
$secondary: #777777;
$white: #fff;

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: 0.3s ease;

  box-shadow: 0 1px 5px 1px rgba($color: #777777, $alpha: 0.5);

  .top-info-bar-container {
    background-color: black;
    opacity: 0.8;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    .top-info-bar {
      width: 1400px;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding: 0 1rem;

      .left-side {
        display: flex;
        width: auto;
        .item {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
      .right-side {
        color: white;
        display: flex;
        justify-content: flex-end;
        width: auto;
      }
      a {
        color: white;
        font-size: 12px;

        text-decoration: none;
        transition: 0.2s ease;
        &:not(:nth-child(2)) {
          margin-right: 1rem;
        }
        i {
          margin-right: 5px;
        }
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .logo-section {
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .logo-container {
      display: flex;
      align-items: center;
      width: 80%;

      .mainlogo {
        max-width: 70px;
        margin-right: 1rem;
        cursor: pointer;
        @include xs {
          max-width: 40px;
        }
        @include sm {
          max-width: 50px;
        }
        @include md {
          max-width: 50px;
        }
      }
      .slogan {
        color: #777777;
        font-size: 0.8rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        cursor: default;
      }
    }

    .lang-menu {
      display: none;
      width: auto;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
      .lang-selection {
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        @include xs {
          font-size: 16px;
        }
        @include sm {
          font-size: 16px;
        }
      }
      .vertical-line {
        width: 3px;
        height: 100%;
        background-color: #d1d0d0;
        margin: 0 0.5rem;
        border-radius: 2px;
      }
      .selected {
        color: $primary;
      }
    }
  }
  .desktop-navbar {
    display: flex;
    max-width: 1400px;
    overflow-x: hidden;
    align-items: center;
    width: 100%;
    padding: 1rem;
    .navbar-button {
      display: none;
    }
    .item-group {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      text-align: center;
      color: #777777;
      .navbar-item {
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          color: orangered;
        }
      }
    }
  }
  .mobile-navbar {
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    .navbar-button {
      display: flex;
      color: #777777;
    }
    .item-group {
      display: none;
      width: 100%;
      justify-content: space-evenly;
      color: #777777;
      padding-left: 0.5rem;
      margin-top: 0.5rem;
      transition: 0.3s ease;

      .navbar-item {
        transition: 0.3s ease;
        padding: 3px 0;
        cursor: pointer;
        &:hover {
          color: orangered;
        }
      }
    }
    .item-group.active {
      display: flex;
      flex-direction: column;
    }
  }
  /* .navbar-section {
    display: flex;
    max-width: 1400px;
    height: 40px;
    align-items: center;
    width: 100%;
    padding: 1rem;
    transition: 0.3s ease;

    @include xs {
      justify-content: flex-start;
    }
    @include sm {
      justify-content: flex-start;
    }
    @include md {
      justify-content: flex-start;
    }
    .navbar-button {
      color: #777777;
      @include lg {
        display: none;
      }
      @include xl {
        display: none;
      }
    }
    .item-group {
      display: flex;
      transition: 0.3s ease;
      justify-content: space-evenly;
      width: 100%;
      text-align: center;
      @include xs {
        display: none;
      }
      @include sm {
        display: none;
      }
      @include md {
        display: none;
      }
      .navbar-item {
        color: #777777;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          color: orangered;
        }
      }
    }
  }
  .active {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    transition: 0.3s ease;
    @include xl {
      flex-direction: row;
      justify-content: space-evenly;
    }
    .navbar-button {
      margin-bottom: 1rem;
    }
    .item-group {
      display: flex;
      flex-direction: column;
      transition: 0.3s ease;
      align-items: flex-start;

      .navbar-item {
        margin-bottom: 3px;
        padding-left: 10px;
      }
    }
  } */
}
</style>
