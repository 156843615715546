import axios from "axios";

export default {
  state: {
    productDetail: [],
    productNames: [],
    categoryPageDetails: [],
    productPageDetails: [],
  },
  mutations: {
    GET_PRODUCT_DETAIL(state, payload) {
      state.productDetail = payload;
    },

    GET_PRODUCT_NAMES(state, payload) {
      state.productNames = payload;
    },
    getCategoryPageDetails(state, payload) {
      state.categoryPageDetails = payload;
    },
    getProductPageDetails(state, payload) {
      state.productPageDetails = payload;
    },
  },
  actions: {
    /* Ürün Detayı Getir*/
    getProductNames({ commit }, payload) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/product/get-product-names`, {
          headers: { id: payload },
        })
        .then((res) => {
          commit("GET_PRODUCT_NAMES", res.data);
        })
        .catch((e) => console.log(e));
    },

    getProductDetail({ commit }, payload) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/product/get-product-detail`, {
          headers: { id: payload },
        })
        .then((res) => {
          commit("GET_PRODUCT_DETAIL", res.data);
        });
    },

    getCategoryPageDetails(context, payload) {
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/product/get-category-page-details`,
          {
            headers: { id: payload },
          }
        )
        .then((res) => {
          context.commit("getCategoryPageDetails", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProductPageDetails(context, payload) {
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/product/get-product-page-details`,
          {
            headers: { id: payload },
          }
        )
        .then((res) => {
          context.commit("getProductPageDetails", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  getters: {},
};
