<template>
  <footer class="footer-container">
    <div class="footer-menu">
      <div class="menu-item">
        <span class="item-header">ÇÖZÜM PASLANMAZ</span>
        <span class="line"></span>
        <span>Çözüm Paslanmaz Çelik Sanayi</span>
      </div>
      <div class="menu-item">
        <span class="item-header">ÜRÜNLERİMİZ</span>
        <span class="line"></span>
        <div class="category-items">
          <span
            v-for="(item, index) in navbar"
            :key="index"
            @click="goTo(item.url, item._id)"
            >{{ item.name }}
          </span>
        </div>
      </div>
      <div class="menu-item">
        <span class="item-header">İLETİŞİM</span>
        <span class="line"></span>
        <div class="contact-items">
          <i class="fas fa-map-marker-alt"></i>
          Has Emek Sanayi Sitesi, 1464. Sokak, No:23, Ostim Sanayi, ANKARA
        </div>
        <div class="contact-items">
          <i class="fas fa-phone-alt"></i>
          + 90 538 766 98 52
        </div>
        <div class="contact-items">
          <i class="fas fa-envelope"></i>
          cozumpaslanmaz@gmail.com
        </div>
      </div>
    </div>
    <span class="line"></span>

    <div class="copyrigth">
      2020 © Copyrigth | Tük Hakları Saklıdır | www.cozumpaslanmaz.net
      <span class="designed">
        Yazılım:
        <a href="https://www.linkedin.com/in/serdariset/"> Serdar İset</a>
      </span>
    </div>
  </footer>
</template>

<script>
import navbar from "@/assets/navbar.json";
export default {
  name: "Footer",
  data() {
    return {
      navbar,
    };
  },
  methods: {
    goTo(url, id) {
      this.$router.push(
        {
          path: `/kategori/${url}`,

          query: { id: id },
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: white;
  margin-top: 2rem;
  .line {
    width: 100%;
    height: 2px;
    background-color: rgba($color: #fff, $alpha: 0.5);
    margin: 1rem 0;
  }
  .footer-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
    max-width: 1400px;
    width: 100%;
    @include xs {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
    @include md {
      width: 100%;
    }
    .menu-item {
      width: 23%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include xs {
        width: 100%;
        margin-bottom: 2rem;
      }
      @include sm {
        width: 100%;
        margin-bottom: 2rem;
      }
      @include md {
        width: 100%;
        margin-bottom: 2rem;
      }
      &:nth-child(2) {
        width: 46%;
        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }
        @include md {
          width: 100%;
        }
      }
      .item-header {
        font-weight: 600;
      }

      .category-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;

        span {
          height: auto;
          padding: 5px 0;
          width: 45%;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            color: orangered;
          }
        }
      }
      .contact-items {
        width: 80%;
        margin-bottom: 1rem;
        i {
          color: orangered;
          margin-right: 10px;
        }
      }
    }
  }
  .copyrigth {
    padding: 0rem 1rem 1rem 1rem;
    font-size: 14px;
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include xs {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
    @include md {
      width: 100%;
    }
    .designed {
      position: absolute;
      display: none;
      right: 0;
      a {
        color: white;
        text-decoration: underline;
      }
      @include xl {
        display: flex;
      }
    }
  }
}
</style>
