<template>
  <div class="detail-product-container">
    <div class="detail-content">
      <div class="rotater" v-if="pageDetail">
        <Rotation :rotate="pageDetail.rotation" />
      </div>
      <div class="content">
        <SideBar :menu="pageDetail.sidebar" />
        <Detail :product="pageDetail.product" />
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../components/ProductPage/SideBar.vue";
import Detail from "../components/ProductPage/Detail.vue";
import Rotation from "../components/global/Rotation.vue";

export default {
  name: "Details",
  components: { SideBar, Detail, Rotation },
  data() {
    return {
      pageDetail: [],
    };
  },
  created() {
    this.getProductPageDetail();
  },

  watch: {
    $route(to, from) {
      let toID = to.query.id;
      let fromID = from.query.id;
      if (toID != fromID) {
        this.getProductPageDetail();
      }
    },
  },
  methods: {
    getProductPageDetail() {
      let id = this.$route.query.id;
      this.$store.dispatch("getProductPageDetails", id).then(() => {
        this.pageDetail = this.$store.state.products.productPageDetails;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/responsive.scss";
.detail-product-container {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  .detail-content {
    width: 1400px;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      justify-content: space-between;
      @include xs {
        padding: 0 1rem;
      }
      @include sm {
        padding: 0 1rem;
      }
      @include md {
        padding: 0 1rem;
      }
      .product-sidebar-container {
        width: 300px;
        display: flex;
        flex-direction: column;
        @include xs {
          display: none;
        }
        @include sm {
          display: none;
        }
        @include md {
          display: none;
        }
      }
      .product-detail-container {
        width: calc(100% - (300px + 2rem));
        border: 1px solid #e0e0e0;
        display: flex;
        flex-direction: column;
        @include xs {
          width: 100%;
          border: none;
        }
        @include sm {
          width: 100%;
          border: none;
        }
        @include md {
          width: 100%;
          border: none;
        }
      }
    }
  }
}
</style>
