<template>
  <div id="app">
    <Header v-if="layoutControll" :categories="categories" />
    <router-view />
    <Footer v-if="layoutControll" :categories="categories" />
  </div>
</template>
<script>
import Header from "@/components/Header/Header.vue";
import Footer from "./components/Footer/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  computed: {
    layoutControll() {
      let pathName = this.$route.name;
      let result = "";
      if (pathName === "AdminLogin" || pathName === "AdminPanel") {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    categories() {
      return this.$store.state.categories.navbarItems;
    },
  },
  created() {
    this.$store.dispatch("getCategories");
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  a {
    text-decoration: none;
  }
}
</style>
