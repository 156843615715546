<template>
  <div class="rotation-container" v-if="rotate">
    <h2 class="rotation-name">{{ rotate.name }}</h2>

    <div class="orientation-links">
      <span class="link-item" @click="gotoHomePage()">anasayfa</span>
      <span class="link-item activePath">
        <i class="fas fa-caret-right"></i> {{ rotate.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rotation",
  props: ["rotate"],
  methods: {
    gotoHomePage() {
      this.$router.push({ path: "/" }, () => {});
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";

.rotation-container {
  margin-bottom: 4rem;

  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include xs {
    padding: 0 1rem;
  }
  @include sm {
    padding: 0 1rem;
  }
  @include md {
    padding: 0 1rem;
  }
  @include lg {
    padding: 0 1rem;
  }

  .rotation-name {
    font-size: 1.5rem;
    color: #777777;
    padding-bottom: 1rem;
  }
  .orientation-links {
    display: flex;
    border-top: 1px solid #e0e0e0;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    width: 100%;
    .link-item {
      display: flex;
      text-transform: capitalize;
      align-items: center;
      transition: 0.2s ease;
      color: #777777;
      cursor: pointer;
      &:hover {
        color: orangered;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
      i {
        margin-right: 0.5rem;
      }
    }
    .activePath {
      color: orangered;
      font-weight: 700;
    }
  }
}
</style>
