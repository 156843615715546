import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import Details from "../views/Details.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/iletisim",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/kategori/:categoryName",
    name: "category",
    component: Product,
  },
  {
    path: "/admin-login",
    name: "AdminLogin",
    component: () => import("../views/Admin/AdminLogin.vue"),
  },
  {
    path: "/admin-panel",
    name: "AdminPanel",
    component: () => import("../views/Admin/AdminPanel.vue"),
  },
  {
    path: "/urun/:productName",
    name: "Details",
    component: Details,
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
