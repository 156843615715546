<template>
  <div class="product-detail-container" v-if="product">
    <div class="product-detail">
      <div class="product-title">
        {{ product.name }}
      </div>
      <div class="product-content">
        <div class="image-section">
          <img
            :src="product.image | setImage"
            :alt="product.name"
            class="image"
            loading="lazy"
          />
        </div>
        <div class="detail-section">
          <div class="detail-item">
            <div class="detail-title">Ürün Kodu</div>
            <div class="property-item">
              <div class="item">{{ product.code }}</div>
            </div>
          </div>

          <div class="detail-item" v-if="product.properties.length > 0">
            <div class="detail-title">Ürün Özellikleri</div>
            <div class="property-item">
              <div
                class="item"
                v-for="(item, index) in product.properties"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-technic" v-if="product.technicImage">
        <div class="detail-title">Teknik Resim</div>
        <img
          :src="product.technicImage | setImage"
          alt=""
          @click="imageURL = product.technicImage"
        />
      </div>
    </div>

    <div class="contact">
      Ürün hakkında detaylı bilgi için bizi
      <b class="link-item" @click="goContactPage()">arayabilir</b> veya
      <b class="link-item" @click="goContactPage()">mesaj</b> gönderebilirsiniz.
    </div>
    <div class="product-image-box" v-if="imageURL">
      <ImageBox :imageURL="imageURL" @closeImage="imageURL = ''" />
    </div>
  </div>
</template>

<script>
import ImageBox from "./ImageBox.vue";
export default {
  components: { ImageBox },
  name: "Detail",
  props: ["product"],
  data() {
    return {
      imageURL: "",
    };
  },
  methods: {
    goContactPage() {
      this.$router.push(
        {
          path: "/iletisim",
        },
        () => {}
      );
    },
  },
  computed: {},
  filters: {
    setImage: function (value) {
      if (!value) return "";
      return `${process.env.VUE_APP_IMAGEURL_ENDPOINT}/${value}?tr=w-500,h-400,cm-pad_resize,bg-000`;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";
.product-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  @include xs {
    padding: 0 1rem;
  }
  @include sm {
    padding: 0 1rem;
  }
  @include md {
    padding: 0 1rem;
  }

  .product-detail {
    width: 100%;
    display: flex;
    flex-direction: column;

    .product-title {
      display: flex;
      width: 100%;
      align-items: center;
      color: #777777;
      font-size: 1.2rem;
      font-weight: 500;
      border-bottom: 1px solid #e0e0e0;
      padding: 0.5rem;
    }
    .product-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @include xs {
        flex-direction: column;
      }
      @include sm {
        flex-direction: column;
      }
      @include md {
        flex-direction: column;
      }
      .image-section {
        max-height: 400px;
        height: 100%;
        display: flex;
        @include xs {
          width: 100%;
          height: 200px;
        }
        @include sm {
          width: 100%;
        }
        @include md {
          width: 100%;
        }
        .image {
          max-height: 100%;
          object-fit: cover;
          @include xs {
            height: 100%;
          }
          @include sm {
            height: 100%;
          }
          @include md {
            max-width: 100%;
          }
        }
      }
      .detail-section {
        width: 50%;
        display: flex;
        flex-direction: column;
        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }
        @include md {
          width: 100%;
        }
        .detail-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          .detail-title {
            font-size: 1.1rem;
            font-weight: 500;
            color: #777777;
            padding: 0.5rem 0;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 0.5rem;
            padding: 1rem 0;
            height: auto;
            overflow-y: hidden;
          }

          .property-item {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 1rem 0;
            height: auto;
            overflow-y: hidden;

            .item {
              padding: 0.5rem;
              background-color: bisque;
              border-radius: 5px;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              display: flex;
              font-size: 14px;
              font-weight: 700;
              color: #777777;
            }
          }
        }
      }
    }
    .product-technic {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 1rem;
      .detail-title {
        color: #777777;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e0e0e0;
        font-weight: 500;
        font-size: 1.2rem;
      }
      img {
        max-width: 100px;
        cursor: pointer;
      }
    }
  }
  .contact {
    width: 100%;
    color: #777777;
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid #e0e0e0;
    padding: 0.5rem;

    cursor: default;
    .link-item {
      color: orangered;
      text-decoration: underline;
      margin: 0 4px;
      cursor: pointer;
    }
  }
}
</style>
