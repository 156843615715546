<template>
  <div class="product-sidebar-container">
    <div class="sidebar-item" @click="goToCategory()">
      <div class="item-header">
        <span>Tüm Ürünler</span>
        <span><i class="fas fa-plus"></i></span>
      </div>
    </div>
    <div class="sidebar-item">
      <div
        class="item-header"
        @click="showButton((this.sideBarDisplay = !this.sideBarDisplay))"
      >
        <span>Kategorideki Ürünler</span>
        <span><i class="fas fa-caret-down"></i></span>
      </div>
      <div class="item-content" :class="{ active: sideBarDisplay }" v-if="menu">
        <div
          v-for="(item, index) in menu.items"
          :key="index"
          class="item-content-text"
          @click="goToDetail(item.name, item.id)"
        >
          <span>{{ item.name }}</span>
          <span><i class="fas fa-caret-right"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
export default {
  name: "SideBar",
  props: ["menu"],
  data() {
    return {
      sideBarDisplay: true,
    };
  },
  methods: {
    goToDetail(name, id) {
      let path = slugify(name, {
        replacement: "-",
        lower: true,
      });
      this.$router.push(
        {
          path: `/urun/${path}`,
          query: {
            id: id,
          },
        },
        () => {}
      );
    },
    goToCategory() {
      this.$router.push(
        {
          path: `/kategori/${this.menu.category.url}`,

          query: {
            id: this.menu.category._id,
          },
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";
.sidebar-item {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #777777;
  margin-bottom: 0.5rem;
  .item-header {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    padding: 0 1rem;
    font-weight: 500;
    transition: 0.3s ease;

    &:hover {
      color: orangered;
    }
  }

  &:nth-child(1) {
    margin-bottom: 2rem;
  }
  .item-content {
    display: none;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 0;
    background-color: #f2f2f281;
    font-size: 14px;
    transition: 1s ease;
    .item-content-text {
      cursor: pointer;
      color: #777777;
      transition: 0.3s ease;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:hover {
        color: orangered;
      }
    }
  }
  .item-content.active {
    display: flex;
    height: auto;
  }
}
</style>
