import axios from "axios";

export default {
  state: {
    messageStatus: false,
    allMessages: [],
  },
  mutations: {
    MESSAGE_STATUS(state, payload) {
      state.messageStatus = payload;
    },
    GET_ALL_MESSAGES(state, payload) {
      state.allMessages = payload;
    },
    DELETE_MESSAGE(state, payload) {
      let index = state.allMessages.findIndex((c) => c._id == payload);
      if (index > -1) state.allMessages.splice(index, 1);
    },
  },
  actions: {
    sendMessage({ commit }, payload) {
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/messages/send-message`,
          payload
        )
        .then(() => {
          commit("MESSAGE_STATUS", true);
        })
        .catch(() => {
          commit("MESSAGE_STATUS", false);
        });
    },
    getMessages({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/messages/get-all-messages`)
        .then((res) => {
          commit("GET_ALL_MESSAGES", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteMessage(context, payload) {
      return axios
        .delete(`${process.env.VUE_APP_API_URL}/api/messages/delete-message`, {
          data: { id: payload },
        })
        .then(() => {
          context.commit("DELETE_MESSAGE", payload);
        });
    },
  },
};
