import axios from "axios";

export default {
  state: {
    productAddStatus: false,
    deleteStatus: false,
    productsInCategory: {},
  },
  mutations: {
    addNewProductStatus(state, payload) {
      state.productAddStatus = payload;
    },
    deleteProductStatus(state, payload) {
      state.deleteStatus = payload;
    },
    getProductsInCategory(state, payload) {
      state.productsInCategory = payload;
    },
  },
  actions: {
    getProductsInCategory({ commit }, payload) {
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/admin/get-products-has-added`,
          { headers: { category: payload } }
        )
        .then((res) => {
          commit("getProductsInCategory", res.data);
        });
    },
    addNewProduct({ commit }, payload) {
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(`${process.env.VUE_APP_API_URL}/api/admin/add-product`, payload, {
          headers,
        })
        .then(() => {
          commit("addNewProductStatus", true);
        })
        .catch(() => {
          commit("addNewProductStatus", false);
        });
    },
    deleteProduct(context, payload) {
      return axios
        .delete(`${process.env.VUE_APP_API_URL}/api/admin/delete-product`, {
          data: { item: payload },
        })
        .then(() => {
          context.commit("deleteProductStatus", true);
        })
        .catch(() => {
          context.commit("deleteProductStatus", false);
        });
    },
    updateProduct(context, payload) {
      const headers = { "Content-Type": "multipart/form-data" };

      return axios
        .patch(
          `${process.env.VUE_APP_API_URL}/api/admin/update-product/${payload.id}`,
          payload.data,
          { headers }
        )
        .then(() => {
          context.commit("deleteProductStatus", true);
        })
        .catch(() => {
          context.commit("deleteProductStatus", false);
        });
    },
  },
  getters: {},
};
