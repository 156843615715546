<template>
  <div class="home-container">
    <!-- Slider -->
    <!-- Intro -->
    <div class="home">
      <div class="about-us">
        <div class="about-item">
          <h1 class="title-header">Çözüm Paslanmaz ne yapar?</h1>

          <p class="info-text">
            <span style="color: orangered"> Çözüm Paslanmaz</span>, modern yapı
            mimarisindeki cam yapılar için gerekli olan çelik üretimini
            yapmaktadır. <br />
            <br />
            Transparan cephe sistemleri, iç ve dış mekan aksesuarları, korkuluk
            sistemleri gibi yapılarda gereksinim duyulan çeliği
            <span style="color: orangered">birinci sınıf kalite</span> de
            üretmektedir. <br />
            <br />
            Son teknoloji üretim araçları ile, alanında uzman mühendisleri ile
            ihtiyacınız olan ürünlerin üretimini ve tedariğini en hızlı şekilde
            sağlamaktadır.
          </p>
        </div>
        <div class="about-item">
          <img :src="require('@/assets/sample.jpg')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style lang="scss">
@import "../scss/responsive.scss";

.home-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .home {
    display: flex;
    width: 1400px;
    .about-us {
      margin-top: 2rem;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      height: auto;

      .about-item {
        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }
        @include md {
          width: 100%;
        }
        width: 47%;
        img {
          max-width: 100%;
          @include xs {
            max-width: 100%;
          }
          @include sm {
            max-width: 100%;
          }
          @include md {
            max-width: 100%;
          }
        }
        .title-header {
          font-size: 40px;
          margin-bottom: 2rem;
          font-weight: bold;
          color: #777777;
          @include xs {
            font-size: 28px;
          }
          @include sm {
            font-size: 28px;
          }
          @include md {
            font-size: 28px;
          }
        }
        .info-text {
          font-size: 20px;
          margin-bottom: 2rem;
          font-weight: 400;
          color: #777777;
        }
      }
    }
  }
}
</style>
